@import "../../../styles/config";

.cardTitle {
  font-family: var(--h1-font-family);
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  color: var(--h1-font-color);
  font-weight: 300;
  text-align: left;
  max-width: var(--h1-max-width);
  @include for-phone-portrait {
    font-family: var(--h1-font-family-xsmall);
    font-size: var(--h1-font-size-xsmall);
    line-height: var(--h1-line-height-xsmall);
  };
  @include for-phone-landscape {
    font-family: var(--h1-font-family-xsmall);
    font-size: var(--h1-font-size-xsmall);
    line-height: var(--h1-line-height-xsmall);
  };
}
