@import "../../../styles/config.scss";

.dropdown {
  margin-bottom: 30px;
  select {
    background-color: #ffffff;
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='438px' height='266px' viewBox='0 0 438 266' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Echevron-down-solid%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='chevron-down-solid' transform='translate(0.656250, 0.494483)' fill='%2337A1B9' fill-rule='nonzero'%3E%3Cpath d='M201.37275,257.981517 L7.02975,63.6375169 C-2.34325,54.2645169 -2.34325,39.0685169 7.02975,29.6965169 L29.69675,7.02951687 C39.05375,-2.32748313 54.21875,-2.34548313 63.59775,6.98951687 L218.34375,161.010517 L373.08875,6.98951687 C382.46775,-2.34548313 397.63275,-2.32748313 406.98975,7.02951687 L429.65675,29.6965169 C439.02975,39.0695169 439.02975,54.2655169 429.65675,63.6375169 L235.31475,257.981517 C225.94175,267.353517 210.74575,267.353517 201.37275,257.981517 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    background-size: 14px 14px;
    appearance: none;
    height: 45px;
    width: 380px;
    border: 1px solid var(--line-color);
    padding: 0px 7px 0px 7px;
    font-size: 19px;
    font-family: var(--font-family-semibold);
    color: var(--text-color);
    &:focus {
      outline: 2px solid var(--control-color);
      border: 1px solid transparent;
    }

    @include for-phone-portrait {
      height: 40px;
      width: 280px;
    }
  }
}
