@import "../../../styles/config.scss";

.radioField {
  margin-bottom: var(--formField-margin-bottom);
}

.RadioList {
  display: block;
  max-width: 450px;
  .RadioListLabelWrap{
    display: block;
    position: relative;
    max-width: 408px;
    margin-bottom: 15px;
    label{
      display: inline-block;
    }
    &>span{
      position: absolute;
      right: 0;
      top: 0;
    }

  }
  @mixin Radio {
    label {
      position: relative;
      padding-left: 0px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      font-size: 16px;
      margin-left: 10px;
      margin-bottom: 15px;
      max-width: 155px;
      @include for-phone-portrait {
        max-width: 100%;
      }
    }
    .optionUnSelected {
      content: "";
      display: inline-block;
      vertical-align: top;
      margin-top: 0px;
      width: 18px;
      height: 18px;
      border: 1px solid var(--line-color);
      border-radius: 100%;
      background: #fff;
      cursor: pointer;
    }
    .optionUnSelectedFirstChild {
      margin-top: 0px;
    }
    .optionSelected {
      content: "";
      vertical-align: top;
      margin-top: 0px;
      display: inline-block;
      width: 14px;
      height: 14px;
      background: var(--heading-color);
      border-radius: 100%;
      transition: all .2s ease;
      margin-left: 2px;
      margin-top: 2px;
    }
    .optionLabelSelected {
      background: var(--heading-color);
    }
    .optionLabelUnSelected {
      background: transparent;
    }
    [type=radio]:not(:checked)+label:after {
      opacity: 0;
      transform: scale(0);
    }
    [type=radio]:checked+label:after {
      opacity: 1;
      transform: scale(1);
    }
    input {
      margin-right: -5px;
      width: 0px;
      -webkit-appearance: none;
      border: 0;
      &+label {
        outline: 2px solid transparent;
      }
    }
    input:focus{
      outline: 2px solid transparent;
      &+label{
        outline: 2px dotted var(--control-color);
      }
    }
  }
  .Radio {
    @include Radio;
  }
  .YesNo, .OptionDescription {
    @include Radio;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
    label {
      max-width: 55px;
    }
  }
  &.boolean .Radio{
    display: inline-block;
    margin-right: 20px;
  }

  .column0 {
    display: inline-block;
    width: 188px;
    margin-right: 10px;
    @include for-phone-portrait {
      width: 100%;
    }
  }
  .column1 {
    display: inline-block;
    width: 188px;
    vertical-align: top;
    @include for-phone-portrait {
      width: 100%;
    }
  }

  .OptionDescription {
    display: block;
    label {
      max-width: 100%;
    }
  }

  .tag {
    margin-bottom: 5px;
  }
}
