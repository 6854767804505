@import "../../../styles/config";

.mainContainer {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include for-phone-portrait {
    background-image: unset;
  }
  @include for-phone-landscape {
    background-image: unset;
  };
}
