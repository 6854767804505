@import "../../../styles/config.scss";

.CheckList {
  display: block;
  .CheckListLabelWrap{
    display: block;
    position: relative;
    max-width: 408px;
    label:not(:empty){
      display: inline-block;
    }
    &>span{
      position: absolute;
      right: 0;
      top: 0;
    }

  }
  @mixin Check {
    label {
      position: relative;
      padding-left: 0px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      font-size: 16px;
      margin-left: 10px;
      margin-bottom: 15px;
    }
    .optionUnSelected {
      content: "";
      display: inline-block;
      vertical-align: top;
      margin-top: 0px;
      width: 18px;
      height: 18px;
      border: 1px solid var(--line-color);
      border-radius: 3px;
      background: #fff;
      cursor: pointer;
    }
    .optionLabelUnSelected {
      background: transparent;
    }
    [type=checkbox]:not(:checked)+label:after {
      opacity: 0;
      transform: scale(0);
    }
    [type=checkbox]:checked+label:after {
      opacity: 1;
      transform: scale(1);
    }
    input {
      margin-right: -5px;
      width: 0px;
      -webkit-appearance: none;
      border: 0;
      &+label {
        outline: 2px solid transparent;
      }
    }
    input:focus{
      outline: 2px solid transparent;
      &+label:not(:empty){
        outline: 2px dotted var(--control-color);
      }
    }
  }
  .Check {
    @include Check;
  }
  &.boolean .Check{
    display: inline-block;
    margin-right: 20px;
  }

  .column0 {
    display: inline-block;
    width: 70%;
    @include for-desktop {
      width: 80%;
    }
    @include for-phone-landscape {
      width: 60%;
    }
    @include for-phone-portrait {
      width: 100%;
    }
  }
  .column1 {
    display: inline-block;
    width: 70%;
    @include for-desktop {
      width: 80%;
    }
    @include for-phone-landscape {
      width: 60%;
    }
    @include for-phone-portrait {
      width: 100%;
    }
  }
}
