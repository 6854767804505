.OneDegreeDesc {
    border: 1px solid var(--placeholder-color);
    padding: 10px 20px;
    width: 685px;
    max-width: 685px;
    line-height: 10px;
    margin-bottom: 30px;
}

.OneDegreeDesc a {
    text-decoration: none;
}

.center {
    text-align: center;
}