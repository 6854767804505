.dataTable {
  button { 
    min-width: auto;
    height: 35px;
    margin-bottom: 0px;
    margin-right: 20px !important;
  }

  .fileLink {
    cursor: pointer;
  }
}
