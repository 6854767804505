@import "../../../styles/config.scss";

.formField {
  input {
    border: 1px solid var(--line-color);
    width: 380px;
    height: 45px;
    background-color: var(--background-color1);
    padding: 10px;
    font-size: 19px;
    font-family: var(--font-family-semibold);
    color: var(--text-color);
    box-sizing: border-box;
    &::placeholder {
      font-size: 19px;
      color: var(--placeholder-color);
      font-family: var(--font-family-semibold);
    }
    &:focus {
      padding: 9px;
      outline: var(--onfocus-outline);
      border: var(--onfocus-border);
      border-radius: var(--onfocus-border-radius);
    }

    @include for-phone-portrait {
      width: 310px;
      height: 40px;
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
