@import '../../../styles/config.scss';

.HintSection{
  font-family: var(--font-family-regular);
  max-width: 725px;
  animation-name: hintFadeIn;
  animation-duration: 350ms;
  animation-delay: calc(var(--order) * 50ms);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  margin-top: 40px;
  border-top: 1px solid var(--line-color);
  padding: 2px;
}
.hintHeading{
  font-size: var(--h2-font-size);
  font-family: var(--h2-font-family);
  line-height: var(--h2-line-height);
  max-width: var(--h2-max-width);
  color: var(--primary-color);
  margin: 30px 0px;

}

.HintSectionList{
  padding-left: 20px;
  max-width: 550px;
  color: var(--text-color);
}

.HintSectionList li{
  animation-name: hintFadeIn;
  animation-duration: 350ms;
  animation-delay: calc(var(--order) * 50ms);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.HintSectionList li+li{
  margin-top: 10px;
}

@keyframes hintFadeIn {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
