@import "../../../styles/config";

.baseStyle {
  min-width: 150px;
  height: 40px;
  text-align: center;
  margin: 15px 20px 25px 0px;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-color: var(--primary-color);
  font-family: var(--font-family-semibold);
  @include for-phone-portrait {
    min-width: 100%;
  }
  @include for-phone-landscape {
    min-width: 100%;
  }
  &:focus {
    outline: var(--onfocus-outline);
    border: var(--onfocus-border);
    border-radius: var(--onfocus-border-radius);
  }
}

.secondary {
  @include for-phone-portrait {
    min-width: 100%;
    margin: 15px 20px 25px 0px
  }
}
