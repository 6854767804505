h4 {
    border: var(--nofocus-border);
}

h4:focus {
    outline: var(--onfocus-outline);
    border: var(--onfocus-border);
    border-radius: var(--onfocus-border-radius);
}

h4:first-child {
    margin-top: 0
}

h4:last-child {
    margin-bottom: 0
}

h4 {
    font-weight: 600;
}

.menuTrigger:hover > i::before {
    cursor: pointer;
    background-color: var(--control-color);
    color: var(--background-color1);
    border: 0px;
}

.focusStyle {
    outline: 0px solid var(--control-color);
    border: 2px solid var(--control-color);
    border-radius: 0px;
}

.itemContainer {
    position: absolute;
    background-color: var(--background-color1);
    z-index: 1;
    margin: 0px 40px 0px -40px;
    border: 1px solid var(--shadow-color);
    box-shadow: 0px 5px 5px var(--shadow-color);
    border-radius: 3px;
    width: 144px;
}

/* Animation for option list */
.optionList {
    animation-name: elFadeIn;
    animation-duration: 250ms;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}

.menuItems:hover {
    cursor: pointer;
    background-color: var(--control-color);
    color: var(--background-color1);
    border: 0px;
}

.menuItems:hover > i::before {
    cursor: pointer;
    background-color: var(--control-color);
    color: var(--background-color1);
    border: 0px;
}

.menuItems {
    text-align: center;
}

.menuItems > i {
    color: var(--control-color);
    padding-left: 10px;
}

.menuActionItems:hover {
    cursor: pointer;
    background-color: var(--background-color1);
    border: 0px;
}

    .menuActionItems:hover > i::before {
        cursor: pointer;
        background-color: var(--background-color2);
        border: 0px;
    }

.menuActionItems > i {
    color: var(--background-color2);
    padding-left: 10px;
}

@keyframes elFadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}