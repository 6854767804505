@import "../../../styles/config";


.initials {
  display: inline-block;
  border: 2px solid var(--h1-font-color);
  width: 29px;
  height: 29px;
  border-radius: 50%;
  text-align: center;
  font-family: var(--font-family-regular);
  margin-right: 10px;

  .letters {
    border: 0px solid;
    vertical-align: sub;
  }
}
