.fieldLabel {
  font-family: var(--font-family-semibold);
  color: var(--text-color);
  line-height: 20px;
  margin-bottom: 7px;
  max-width: 335px;
  max-width: 380px;
  display: block;
}

.requiredTag {
  font-size: 11pt;
  color: var(--alert-color);
  line-height: 20px;
  display: inline-block;
  margin-left: 0.5em;
}
