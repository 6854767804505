:root {
    --primary-color: #6C3B96;
    --heading-color: #37A1B9;
    --control-color: #37A1B9;
    --text-color: #54545C;
    --line-color: #757575;
    --line-color-background: rgba(117, 117, 117, 0.4);
    --placeholder-color: #939593;
    --background-color1: #FFFFFF;
    --background-color2: #F2F2F2;
    --background-color3: rgba(0, 0, 0, 0.2);
    --shadow-color: #E4E4E4;
    --shadow-color2:#404040 ;
    --alert-color: #CC0000;
    --accent-color1: #DA7C01;
    --accent-color2: #028850;
    --accent-color3: #961263;
    --accent-color4: #D63947; 
    --semibold-font: Lato-Semibold; 
    --icon-color: #555555; 

    --font-family-regular: Lato-Regular, Lato, sans-serif;
    --font-family-light: Lato-Light, Lato, sans-serif;
    --font-family-semibold: Lato-Semibold, Lato, sans-serif;
    --font-family-bold: Lato-Bold, Lato, sans-serif;
    --text-decoration: underline;
   
    --h1-font-family: var(--font-family-light);
    --h1-font-size: 50px;
    --h1-line-height: 58px;
    --h1-font-color: var(--primary-color);
    --h1-max-width: 725px;

    --h2-font-family: var(--font-family-semibold);
    --h2-font-size: 14.5pt;
    --h2-line-height: 28px;
    --h2-max-width: 550px;
    --h2-width: 550px;
    --h2-font-color: var(--heading-color);
    --h2-margins: 0 0 40px 0;
   
    --h3-font-family: var(--font-family-semibold);
    --h3-font-size: 16px;
    --h3-font-color: var(--text-color);
    --h3-max-width: 335px;
    --h3-margins: 0 0 40px 0;
   
    --p-font-family: var(--font-family-regular);
    --p-font-size: 16px;
    --p-line-height: 20px;
    --p-font-color: var(--text-color);
    --p-max-width: 550px;
    --p-padding: 0 0 0 15px;
    --p-text-align: left;

    --column-label-font-family: var(--font-family-semibold);
    --column-label-font-size: 16px;
    --column-label-line-height: 20px;
    --column-label-margin: 0 7px 0 0;
   
    --column-text-font-family: var(--font-family-regular);
    --column-text-font-size: 16px;
    --column-text-line-height: 20px;
    --column-text-font-color: var(--text-color);
    --column-text-margin: 0 7px 0 0;

    /* X-SMALL Breakpoint styles */

    --h1-font-family-xsmall: var(--font-family-regular);
    --h1-font-size-xsmall: 30px;
    --h1-line-height-xsmall: 36px;

    --p-max-width-xsmall: 335px;

    /* Common components */
    --login-card-width: 440px;
    --login-card-max-height: 700px; 
    --card-max-width: 1020px;
    --formField-margin-bottom: 25px;

    --onfocus-outline: 0px solid var(--control-color);
    --onfocus-border: 2px solid var(--control-color);
    --nofocus-border: 2px solid transparent;
    --onfocus-border-radius: 0px;

 }

 h1 {
    font-family: var(--h1-font-family);
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
    max-width: var(--h1-max-width);
    color: var(--h1-font-color);
}

h2 {
    font-family: var(--h2-font-family);
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
    max-width: var(--h2-max-width);
    /* width: var(--h2-width); */
    color: var(--h2-font-color);
}