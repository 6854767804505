@import "../../../styles/config";

.card {
  background-color: var(--background-color1);
  box-shadow: var(--shadow-color) 0px 5px 5px;
  border-radius: 3px;
  padding: 40px 40px 20px 40px;
  margin: 15px 50px;
  max-width: var(--card-max-width);
  text-align: left;
  @include for-phone-portrait {
    margin: 15px 0px;
    padding: 30px 30px 20px 30px;
  }
  @include for-phone-landscape {
    margin: 15px 30px;
  };
  @include for-tablet-portrait {
    margin: 15px 30px;
  }
}

.LoginCard {
  box-shadow: unset;
  border-radius: 0px;
  width: var(--login-card-width);
  max-height: var(--login-card-max-height);
  padding: 40px;
  margin: 40px;
  display: inline-block;
  @include for-phone-portrait {
    width: -webkit-fill-available;
    width: -moz-available;
    max-height: unset;
    margin: 20px 0px 30px 0px;
    padding:  30px 20px;
  }
  @include for-phone-landscape {
    max-height: unset;
    width: -webkit-fill-available;
    width: -moz-available;
  };
}
