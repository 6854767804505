// X-Small Breakpoint
@mixin for-phone-portrait {
  @media (max-width: 575px) {
    @content;
  }
}
// Small Breakpoint
@mixin for-phone-landscape {
  @media (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}
// Medium Breakpoint
@mixin for-tablet-portrait {
  @media (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}
// Large Breakpoint
@mixin for-tablet-landscape {
  @media (min-width: 992px) and (max-width: 1200px) {
    @content;
  }
}
// X-Large Breakpoint
@mixin for-desktop {
  @media (min-width: 1201px) {
    @content;
  }
}
