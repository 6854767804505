@import "../../styles/config";

.top {
  display: block;
  max-width: 955px;
  padding: 0px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.linksWrapper {
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  @include for-phone-portrait {
    display: block;
  }
  @include for-phone-landscape {
    display: block;
  }
}

.brandingLinksWrapper {
  margin-top: 0px;
  display: block;
  justify-content: left;
  text-align: left;
  @include for-phone-portrait {
    margin-top: 30px;
    text-align: center;
  }
  @include for-phone-landscape {
    margin-top: 30px;
    text-align: center;
  }
}

.threeLinks span:first-of-type {
  @include for-phone-portrait {
    width: 100%;
  }
  @include for-phone-landscape {
    width: 100%;
  }
}

.hideSeparator {
  @include for-phone-portrait {
    display: none;
  }
  @include for-phone-landscape {
    display: none;
  }
}

.poweredBy {
  font-family: var(--font-family-regular);
  color: var(--text-color);
  text-align: center;
}

.socialLinksFooter {
  margin-top: 30px;
  text-align: center;
}

.marginTop7 {
  margin-top: 7px;
}

.logo {
  width: 200px;
  flex: 0 1 auto;
  @include for-phone-portrait {
    padding: 0;
  }
  @include for-phone-landscape {
    padding: 0;
  }
}

.linkSpan {
  @include for-phone-portrait {
    display: inline-block;
  }
  @include for-phone-landscape {
    display: inline-block;
  }
}

.brandingLinkSpan {
  display: block;
  width: 100%;
}

.footerlogo {
  border: var(--nofocus-border);
  display: flex;
  justify-content: center;
  &:focus {
    outline: var(--onfocus-outline);
    border: var(--onfocus-border);
    border-radius: var(--onfocus-border-radius);
  }
}

.brandingDivs {
  display: inline-block;
  vertical-align: middle;
  @include for-phone-portrait {
    width: 100%;
  }
  @include for-phone-landscape {
    width: 100%;
  }
}

.brandingLogo {
  margin-right: 40px;
}

.footerDivider {
  @include for-phone-portrait {
    margin: auto 30px;
  }
  @include for-phone-landscape {
    margin: auto 30px;
  }
}
