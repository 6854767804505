@import "../../../styles/config";

.navigation {
  border: 0px solid lightgray;
  padding: 0 50px;
  text-align: left;
  max-width: var(--card-max-width);
  @include for-phone-portrait {
    padding: 0 30px;
  };
  @include for-phone-landscape {
    padding: 0 30px;
    margin: 0 40px;
  };
  @include for-tablet-portrait {
    padding: 0 40px;
    margin: 0 30px;
  };
  @include for-tablet-landscape {
    padding: 0 40px;
    margin: 0 50px;
  };
}

.inlineNavigation {
  padding: 0;
  @include for-phone-portrait {
    padding: 0;
    margin: 0;
  };
  @include for-phone-landscape {
    padding: 0;
    margin: 0;
  };
  @include for-tablet-portrait {
    padding: 0;
    margin: 0;
  };
  @include for-tablet-landscape {
    padding: 0;
    margin: 0;
  };
}

.innerNavigation {
  max-width: var(--h1-max-width);
  @include for-phone-portrait {
    text-align: center;
  };
}

.position-left {
  float: none;
}

.position-right {
  float: right;
  @include for-phone-portrait {
    float: none;
  };
  @include for-phone-landscape {
    float: none;
  };
}