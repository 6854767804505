@font-face {
  font-family: "lato-light";
  src: url("./assets/fonts/lato-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-light-webfont.woff") format("woff"),
    url("./assets/fonts/Lato-Light.ttf") format("truetype");
}
@font-face {
  font-family: "lato-regular";
  src: url("./assets/fonts/lato-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-regular-webfont.woff") format("woff"),
    url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "lato-semibold";
  src: url("./assets/fonts/lato-semibold-webfont.woff2") format("woff2"),
    url("./assets/fonts/lato-semibold-webfont.woff") format("woff"),
    url("./assets/fonts/Lato-SemiBold.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: "lato-regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  line-height: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
