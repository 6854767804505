@import "../../../styles/config.scss";

.helpLink {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 15px;
  cursor: pointer;
  &:focus {
    outline: 2px solid var(--control-color);
    box-shadow: 0 0 1px 2px var(--control-color);
  }
}

.helpLinkBg {
  fill: var(--primary-color);
}

.helpPopover {
  width: 305px;
  position: relative;
  background: var(--background-color1);
  border: 3px solid var(--primary-color);
  text-align: center;
  box-sizing: border-box;
  @include for-phone-portrait {
    width: unset;
  }
}

.helpTitle {
  text-align: center;
  font-family: var(--h2-font-family);
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  color: var(--h2-font-color);
  margin: 20px 0px;
}

.helpText {
  margin: 0px 30px;
}

.helpSeparator {
  color: var(--line-color);
  max-width: 200px;
  margin-top: 30px;
  margin-bottom: 15px;
  width: 65%;
}

.helpCancel {
  text-align: center;
  font-family: var(--font-family-semibold);
  cursor: pointer;
  margin-bottom: 15px;
  line-height: 15px;
}
