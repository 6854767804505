@import "../../styles/config";

.displayTitle {
  @include for-phone-portrait {
    display: block;
  }
}

.titleStyle {
  position: absolute;
  right: 0px;
}

.titleStyleOpen {
  position: relative;
}

.menuTitle {
  border: 1px solid var(--nofocus-border);
  &:focus {
    outline: var(--onfocus-outline);
    border: var(--onfocus-border);
    border-radius: var(--onfocus-border-radius);
  }
}

.linksWrapper {
  margin-top: 5px;
  justify-content: right;
  align-items: right;
  display: flex;
  @include for-phone-portrait {
    display: block;
  }
  @include for-phone-landscape {
    display: block;
  }
}

.hideSeparator {
  @include for-phone-portrait {
    display: none;
  }
  @include for-phone-landscape {
    display: none;
  }
}

.linkSpan {
  @include for-phone-portrait {
    display: inline-block;
  }
  @include for-phone-landscape {
    display: inline-block;
  }
}

// Add CSS for languageSelectorWrapper
.languageSelectorWrapper {
  margin-left: 20px; // Adjust the margin as needed
}
