@import "../../../styles/config";

.pText {
  font-family: var(--p-font-family);
  line-height: var(--p-line-height);
  text-align: inherit;
  max-width: var(--p-max-width);
  a {
    color: var(--primary-color);
  };
  &.inlineDisplay {
    display: inline-block;
  }
  @include for-phone-portrait {
    max-width: var(--p-max-width-xsmall);
  };
  h1 {
    color: var(--heading-color);
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
    font-family: var(--h2-font-family);
  }
}
