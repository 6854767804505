@import "../../../styles/config";

.logoLink {
  text-decoration: none;
  color: var(--text-color);
  padding: 2px;
  border: var(--nofocus-border);
  &:focus {
    outline: var(--onfocus-outline);
    border: var(--onfocus-border);
    border-radius: var(--onfocus-border-radius);
  };
  p {
    color: var(--text-color);
    font-size: 12pt;
    font-weight: bold;
  }
}
