@import "../../../styles/config";

.displayTitle {
}

.titleStyle {
  position: absolute;
  right: 0px;
}

.titleStyleOpen {
  position: relative;
}