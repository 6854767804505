@import "../../../styles/config";

.linkContainer {
  flex: 0 1 auto;
  font-size: 15px;
  font-family: var(--font-family-regular);
  vertical-align: bottom;
  color: var(--text-color);
  @include for-phone-portrait {
    margin-top: 20px;
  }
}

.linkContainer a {
  color: var(--text-color);
  text-decoration: none;
}

.linkContainer a:hover {
  text-decoration: underline;
}

.plainLink {
  text-decoration: none;
  color: var(--text-color);
  padding: 2px;
  border: var(--nofocus-border);
}

.link {
  text-decoration: none;
  color: var(--text-color);
  padding: 2px;
  border: var(--nofocus-border);
  &:focus {
    outline: var(--onfocus-outline);
    border: var(--onfocus-border);
    border-radius: var(--onfocus-border-radius);
  }
}

.link:hover {
  text-decoration: underline;
}

.socialLink {
  display: inline-block;
  font-size: 20px;
  text-decoration: none;
  margin-right: 20px;
  color: var(--text-color);
  border: var(--nofocus-border);
  &:focus {
    outline: var(--onfocus-outline);
    border: var(--onfocus-border);
    border-radius: var(--onfocus-border-radius);
  }
}

.socialLink:hover {
  color: var(--primary-color);
}
