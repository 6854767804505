.autocomplete {
    margin-bottom: 30px;
}

.autocomplete input {
    height: 29px;
    width: 364px;
    max-width: 364px;
    padding: 7px;
    border: 1px solid var(--line-color);
    font-size: 19px;
    font-family: var(--font-family-semibold);
    color: var(--text-color);
}

.autocomplete input:focus {
    border: 2px solid var(--control-color);
    outline: none;
}

.autocomplete ul {
    padding-inline-start: 0px;
}

.autocomplete ul li {
    list-style-type: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
    border: 1px solid var(--line-color);
    width: 380px;
    font-weight: 300;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}