@import "../../../styles/config";

.cardTitleLine {
  max-width: var(--h1-max-width);
}

.line {
  background-color: var(--line-color);
  margin: 40px auto;
  height: 1.0px;
  border: 0px;
  @include for-phone-portrait {
    margin: 30px auto;
  };
  @include for-phone-landscape {
    margin: 30px auto;
  };
}
